<template>
  <v-row class="match-height">
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-card-text>
          <v-form
            id="form"
            ref="form"
            v-model="isValid"
            class="multi-col-validation"
            :loading="loadingSaveForm"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  label="Nombre"
                  outlined
                  dense
                  placeholder="Nombre"
                  :rules="[
                    (v) => !!v || 'El campo nombre es obligatorio',
                    (v) => (!!v && v.length <= 50) || 'El campo nombre admite hasta 50 caracteres',
                  ]"
                  counter="50"
                  maxlength="50"
                  required
                  error-count="2"
                  :error="campos.name.error"
                  :error-messages="errorMsgName"
                >
                  <template #label>
                    <app-label
                      field="Nombre"
                      required="true"
                    />
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  :disabled="!isValid"
                  :loading="loadingSaveForm"
                  @click="saveForm"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { AppLabel, Breadcrumbs },
  created() {
    if (this.$route.params.obj != null) {
      this.obj.id = this.$route.params.obj.id
      this.obj.name = this.$route.params.obj.name
      this.name = this.$route.params.obj.name
      this.formTitulo = this.$route.params.formTitulo
      this.isValid = true
      this.items = this.$route.params.items
    }
  },
  setup() {
    const { router } = useRouter()
    const formTitulo = ref('Crear categoría')
    const loadingSaveForm = ref(false)
    const name = ref(null)
    const code = ref(null)
    const obj = ref({
      id: null,
      name: null,
      code: null,
    })
    const form = ref(null)
    const isValid = ref(true)
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Categorías',
        disabled: false,
        href: '/gestion-categorias-eventos',
      },
      {
        text: `${formTitulo.value}`,
        disabled: true,
        href: '/gestion-categorias-eventos/form',
      },
    ])
    const campos = reactive({
      name: {
        error: false,
        mensajesError: [],
      },
      code: {
        error: false,
        mensajesError: [],
      },
    })
    const errorMsgName = computed(() => (campos.name.error ? campos.name.mensajesError : []))
    function limpiarForm() {
      name.value = null
      code.value = null
      obj.value = {
        id: null,
        name: null,
        code: null,
      }
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    return {
      formTitulo,
      loadingSaveForm,
      name,
      code,
      obj,
      form,
      isValid,
      items,
      campos,
      errorMsgName,
      notificaciones,
      saveForm: async () => {
        if (loadingSaveForm.value) return
        if (isValid && form.value.validate()) {
          loadingSaveForm.value = true
          campos.value = reactive({
            name: {
              error: false,
              mensajesError: [],
            },
          })
          const url = obj.value.id == null ? `${HTTP.defaults.baseURL}events-api/event-categories` : `${HTTP.defaults.baseURL}events-api/event-categories/${obj.value.id}`
          const objeto = {}
          objeto.name = name.value
          const resp = obj.value.id == null ? [HTTP.post(url, objeto)] : [HTTP.put(url, objeto)]
          await Promise.all(resp)
            .then(response => {
              notificaciones.snackbar = true
              notificaciones.color = 'success'
              notificaciones.text = obj.value.id == null ? 'El registro ha sido creado satisfactoriamente' : 'El registro ha sido actualizado satisfactoriamente'
              loadingSaveForm.value = false
              router.push({
                name: 'gestion_categorias_eventos',
                params: {
                  notificaciones: {
                    snackbar: notificaciones.snackbar,
                    text: notificaciones.text,
                    color: notificaciones.color,
                  },
                },
              })
            })
            .catch(error => {
              const { errors, title } = error.response.data
              if (errors) {
                let errorDialog = true
                if (errors.Name) {
                  for (let index = 0; index < errors.Name.length; index += 1) {
                    campos.name.mensajesError.push(errors.Name[index])
                  }
                  errorDialog = false
                  campos.name.error = true
                }
                if (errorDialog) {
                  let error = ''
                  const keysError = Object.keys(errors)
                  for (let index = 0; index < keysError.length; index += 1) {
                    for (let index1 = 0; index1 < errors[keysError[index]].length; index1 += 1) {
                      error += errors[keysError[index]][index1]
                    }
                  }
                  notificaciones.snackbar = true
                  notificaciones.color = 'error'
                  notificaciones.text = error
                }
              }
              loadingSaveForm.value = false
            })
        }
      },
      cancelarForm: () => {
        limpiarForm()
        router.push({ name: 'gestion_categorias_eventos' })
      },
    }
  },
}
</script>

<style>

</style>
